import { makeStyles } from "@material-ui/core/styles";

const breakPoint = "975";
const useStyles = makeStyles((theme) => ({
  span: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 16,
    marginRight: 20,
    cursor: "pointer",
    [theme.breakpoints.down(breakPoint)]: {
      fontSize: 22,
      padding: 0,
      marginRight: 6,
    },
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 20,
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down(breakPoint)]: {
      width: 25,
      height: 25,
      marginRight: 5,
    },
  },
  navlink: {
    border: "none",
    outline: "none",
    color: "#344450",
    width: "auto",
    backgroundColor: "inherit",
    margin: 0,
    fontSize: 14,
    // fontWeight: "bold",
    [theme.breakpoints.down("1024")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(breakPoint)]: {
      color: "#e4e4e4",
      padding: "3px 10px",
    },
  },
  navlinkList: {
    border: "none",
    outline: "none",
    color: "#344450",
    // paddingRight: "1rem",
    // paddingLeft: "1rem",
    // paddingTop: "2rem",
    // paddingBottom: "2rem",
    width: "auto",
    backgroundColor: "inherit",
    margin: 0,
    fontSize: 14,
    // fontWeight: "bold",
    [theme.breakpoints.down("1024")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(breakPoint)]: {
      color: "#e4e4e4",
      // padding: "3px 10px",
    },
  },
  dropdownEN: {
    "& a": {
      float: "none",
      color: "#000",
      textDecoration: "none",
      display: "block",
      padding: "10px 16px",
      margin: "5px 8px",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#f5b750",
        borderRadius: "10px",
      },
    },
    [theme.breakpoints.up(breakPoint)]: {
      display: "none",
      position: "absolute",
      marginTop: 0,
      backgroundColor: "#fff",
      minWidth: 200,
      maxWidth: 200,
      paddingTop: "5px",
      paddingBottom: "5px",
      zIndex: 100,
      borderRadius: "12px",
      boxShadow: "0px 4px 4px 0px #00000040",
    },
    [theme.breakpoints.down(breakPoint)]: {
      fontSize: 12,
      margin: 0,
      display: "none",
      position: "relative",
      padding: "5px 0px 0px 0px",
    },
  },
  dropdownAR: {
    "& a": {
      float: "none",
      color: "#000",
      textDecoration: "none",
      display: "block",
      padding: "10px 16px",
      margin: "5px 8px",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#f5b750",
        borderRadius: "10px",
      },
    },
    [theme.breakpoints.up(breakPoint)]: {
      display: "none",
      position: "absolute",
      marginTop: 0,
      backgroundColor: "#fff",
      minWidth: 200,
      maxWidth: 200,
      zIndex: 100,
      paddingTop: "5px",
      paddingBottom: "5px",
      borderRadius: "12px",
      boxShadow: "0px 4px 4px 0px #00000040",
    },
    [theme.breakpoints.down(breakPoint)]: {
      fontSize: 12,
      margin: 0,
      display: "none",
      position: "relative",
      padding: "5px 0px 0px 0px",
    },
  },
  divNavLink: {
    "&:nth-child(1):hover": {
      "& .dropdown1": {
        display: "block",
      },
    },
    "&:nth-child(3):hover": {
      "& .dropdown2": {
        display: "block",
      },
    },
    "&:nth-child(6):hover": {
      "& .dropdown3": {
        display: "block",
      },
    },
    [theme.breakpoints.down(breakPoint)]: {
      fontSize: 12,
      padding: "15px 5px",
    },
  },
  logoutDivLink: {
    display: "none",
    color: "#E4E4E4",
    fontSize: 12,
    padding: "15px 15px",
    [theme.breakpoints.down(breakPoint)]: {
      display: "block",
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default useStyles;
