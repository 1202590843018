import Ar from "./ar";
import En from "./en";
import Fr from "./fr";

let strings = {
  en: En,
  fr: Fr,
  ar: Ar,
};

export default strings;
