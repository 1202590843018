import Header from "components/Header";
import SideMenu from "components/sideMenu";
import dynamic from "next/dynamic";

const DynamicFooter = dynamic(import("components/Footer"), {
  ssr: false,
});
export default function Layout({ children }) {
  return (
    <div className="drawer">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content min-h-screen flex flex-col justify-between">
        <Header />
        {children}
        <DynamicFooter />
      </div>
      <div className="drawer-side z-50">
        <label
          htmlFor="my-drawer-4"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <SideMenu />
      </div>
    </div>
  );
}
