"use client";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import useTranslation from "translations/hooks/useTranslation";
import SessionContext from "./session/SessionContext";
import Text from "./Text";
import router from "next/router";
import { mobileDim } from "functions/utils";
import Button from "./Button";
import LinkNext from "next/link";
import { fetchFromAPI } from "functions";
import { newsLinks, oeaLinks, technicalOfficeLinks } from "data/menuItems";
import { isEmpty } from "lodash";

const NavBarLinks = [
  {
    id: 1,
    title: "oea",
    links: oeaLinks,
  },
  {
    id: 2,
    title: "financialAndMedicalTransactions",
    href: "/financialMedicalTransactions",
    as: "/financialMedicalTransactions",
  },
  {
    id: 3,
    title: "technicalOffice",
    links: technicalOfficeLinks,
  },
  {
    id: 4,
    title: "arbitrationCenter",
    href: "/arbitration",
    as: "/arbitration",
  },
  {
    id: 5,
    href: "/contactus/trainingcenter",
    as: "/contactus/trainingcenter",
    title: "contactTrainingCenter",
  },
  {
    id: 6,
    title: "news",
    links: newsLinks,
  },
  {
    id: 7,
    title: "eorder",
    href: '/eorder',
    as: "/eorder",
  },
  {
    id: 8,
    href: "/contactus",
    as: "/contactus",
    title: "contactUs",
  },
];
const SideMenu = () => {
  const isMobile = useMediaQuery(mobileDim);
  const { t } = useTranslation();
  const [selectedLink, setSelectedLink] = useState("");

  let {
    actions: { signout, setSession },
    session: { user, lang },
  } = useContext(SessionContext);
  let isLoggedIn = user && user?.authToken && user?._id;

  const [state, setState] = useState({
    facebook: "",
    twitter: "",
    supportPhoneNumber: "",
    supportEmail: "",
  });

  useEffect(() => {
    function fetchData() {
      fetchFromAPI({ api: "footer" }).then((response) => {
        let result = response.response;
        let { facebook, twitter, supportPhoneNumber, supportEmail } = result;
        setState({
          facebook,
          twitter,
          supportPhoneNumber,
          supportEmail,
        });
      });
    }
    fetchData();
  }, []);

  const renderMenuLink = (item, index) => {
    let links = item?.links;

    let isSelected = selectedLink == item?.id + "";

    return (
      <label key={index} className="collapse mb-1">
        {links?.length > 0 && <input type="checkbox" className="peer" />}
        {links?.length > 0 && (
          <Icon
            icon={"ep:arrow-down-bold"}
            className={`transition-all absolute z-10 cursor-pointer peer-checked:rotate-180 ${lang == "ar" ? "left-0" : "right-0"}  top-[20px] bg-orange rounded-[6px] py-1 px-1 w-6 h-6`}
            fontSize={20}
            color="white"
          />
        )}

        {links?.length > 0 ? (
          <div className="collapse-title flex flex-row items-center h-12">
            <Text
              onClick={() => {
                if (!isMobile) return;
                if (isSelected) setSelectedLink("");
                else setSelectedLink(item?.id?.toString());

                // router.push(`/${lang}${item?.href ?? "/"}`);
              }}
              disableSelect
              normal
              className={`hover:scale-110 hover:transition-all cursor-pointer`}
              text={t(item?.title)}
            />
          </div>
        ) : (
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            onClick={() => {
              if (item?.link?.includes("https")) {
                typeof window !== "undefined" &&
                  window.open(item?.href, "_blank");
              } else {
                router.push(`/${lang}/${item?.href ?? "/"}`);
              }
            }}
            className="cursor-pointer hover:bg-lightGrey"
          >
            <div className="collapse-title h-12 flex flex-row items-center">
              <Text
                onClick={() => {
                  if (!isMobile) return;
                  if (isSelected) setSelectedLink("");
                  else setSelectedLink(item?.id?.toString());
                }}
                disableSelect
                normal
                className={`cursor-pointer text-black`}
                text={t(item?.title)}
              />
            </div>
          </label>
        )}

        {isEmpty(links) || !links ? null : (
          <div className="collapse-content pr-0">
            {links?.map((link, index) => renderLink(link, index))}
          </div>
        )}
      </label>
    );
  };

  const renderLink = (link, index) => {
    return (
      <label
        key={index}
        htmlFor="my-drawer-4"
        aria-label="close sidebar"
        className="hover:scale-110 cursor-pointer transition-all duration-300 hover-to-show"
        onClick={() => {
          router.push(`/${lang}/${link?.href}`);
        }}
      >
        <li>
          <Text
            disableSelect
            text={t(link?.title)}
            className={`mx-2 hover:font-semibold text-black`}
            mNormal
          />
        </li>
      </label>
    );
  };

  return (
    <div className="relative menu py-7 p-4 w-80 min-h-full rounded-tr-3xl rtl:rounded-tr-none rtl:rounded-tl-3xl bg-white pb-24">
      <div className="w-full flex justify-center items-center mb-8">
        <Text
          disableSelect
          sectionTitle
          className={`my-[6px] font-primary4 text-input text-black `}
          text={t("sideMenuTitle")}
        />
      </div>

      <label
        htmlFor="my-drawer-4"
        aria-label="close sidebar"
        className="cursor-pointer absolute z-50 top-8 left-5"
      >
        <Icon
          icon={"mingcute:close-fill"}
          className={`w-5 h-5 md:w-7 md:h-7 text-black  `}
        />
      </label>
      {!isLoggedIn ? (
        <div className="flex flex-col cursor-pointer">
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="cursor-pointer"
            onClick={() => {
              router.push(`/${lang}/signin`);
            }}
          >
            <Button
              title={t("signIn")}
              className="w-full pointer-events-none flex items-center justify-center"
            />
          </label>
          <label
            htmlFor="my-drawer-4"
            className="cursor-pointer"
            aria-label="close sidebar"
            onClick={() => {
              router.push(`/${lang}/enroll`);
            }}
          >
            <Button
              title={t("signUp")}
              whiteBg
              outline={true}
              className="w-full pointer-events-none flex items-center justify-center"
            />
          </label>
        </div>
      ) : (
        <label
          htmlFor="my-drawer-4"
          aria-label="close sidebar"
          className="flex w-full flex-row items-center justify-between cursor-pointer"
          onClick={() => {
            router.push(`/${lang}/userProfile`);
          }}
        >
          <div className="rounded-full bg-gray-200 w-8 h-8 flex items-center justify-center">
            <Icon icon={"mdi:account"} className="text-2xl" />
          </div>
          <Text
            disableSelect
            w4
            normal
            className={`w-9/12 cursor-pointer px-2 text-black truncate`}
            text={user?.profile?.emailAddress || user?.profile?.firstName || ""}
          />
          <Icon icon="ep:arrow-left-bold" className="text-normal" />
        </label>
      )}
      <div className="divider my-2 text-lightGrey " />
      {NavBarLinks?.map((item, index) => renderMenuLink(item, index))}
      <div className="divider my-2 text-lightGrey " />
      <div
        className={"flex flex-row items-center justify-center mb-10 md:mb-0"}
      >
        <LinkNext href={`${state.facebook}`}>
          <a
            target="_blank"
            aria-label="Facebook"
            className="mx-2 border-2 border-[#FFFFFF55] rounded-[8px] w-8 h-8 flex items-center justify-center"
          >
            <Icon icon="brandico:facebook" width={18} height={18} />
          </a>
        </LinkNext>
        <LinkNext href={`${state.twitter}`}>
          <a
            target="_blank"
            aria-label="Twitter"
            className="mx-2 border-2 border-[#FFFFFF55] rounded-[8px] w-8 h-8 flex items-center justify-center"
          >
            <Icon icon="ri:twitter-x-fill" width={24} height={24} />
          </a>
        </LinkNext>

        <LinkNext href={`mailto:${state.supportEmail}`}>
          <a
            target="_blank"
            aria-label="Email"
            className="mx-2 border-2 border-[#FFFFFF55] rounded-[8px] w-8 h-8 flex items-center justify-center"
          >
            <Icon icon="ic:round-email" width={28} height={28} />
          </a>
        </LinkNext>
        <LinkNext href={`tel:${state.supportPhoneNumber}`}>
          <a
            target="_blank"
            aria-label="Phone Number"
            className="mx-2 border-2 border-[#FFFFFF55] rounded-[8px] w-8 h-8 flex items-center justify-center"
          >
            <Icon icon="fluent:call-24-filled" width={28} height={28} />
          </a>
        </LinkNext>
      </div>
      {/* <div className="flex flex-row justify-center items-center mt-4">
        {!isEmpty(socialMedias)
          ? socialMedias?.map((item) => {
              return (
                <a
                  key={item.id}
                  className="cursor-pointer border-2 mx-2 border-[rgba(255,255,255,0.5)] rounded-inputsBorder flex justify-center items-center"
                  href={item?.value ?? "/"}
                  target="_blank"
                >
                  <Icon
                    icon={item?.icon_name ?? ""}
                    className="text-title text-[rgba(255,255,255,0.5)] w-8 h-8"
                  />
                </a>
              );
            })
          : null}
      </div> */}
      {!isLoggedIn ? null : (
        <div
          onClick={signout}
          className="mt-8 cursor-pointer w-full flex rounded-cardBorder flex-row items-center justify-center hover:bg-lightGrey "
        >
          <Icon icon={"humbleicons:logout"} className="ml-4 text-subtitle" />
          <Text
            titleColors
            disableSelect
            w4
            normal
            center
            className="  py-3 text-light-progressBar hover:text-light-title text-subtitle"
            text={isLoggedIn ? t("logout") : t("login")}
          />
        </div>
      )}
    </div>
  );
};

export default SideMenu;
