export default {
  oea: "OEA",
  exploreOEA: "Explorez OEA",
  news: "Nouvelles",
  logout: "Sortir",
  login: "Entrer",
  homeTitle: "Ordre Des Ingénieurs & Architectes <br/>  à Tripoli, Liban",
  footerCopyrights: "Ordre Des Ingénieurs à Tripoli",
  aboutOEA: "À Propos De l'Ordre",
  boardOfMembers: "Conseil D'Administration",
  organizationChart: "Organigramme",
  branches: "Branches",
  branchesSubtitle:
    "Conformément à l'article 6-4 du règlement intérieur du syndicat, chaque branche doit avoir un bureau composé d'un président, qui est le représentant de la branche au conseil du syndicat, et de quatre membres élus par l'assemblée générale de la branche conformément à l'article 6-3 de ce règlement. Les élections des bureaux de branche dont les mandats sont arrivés à expiration sont organisées par l'assemblée générale de chaque branche durant le mois de mai de chaque année pour une durée de trois ans.",
  associations: "Comités",
  trainings: "Les Entrainements",
  events: "Evénements",
  title: "Titre",
  gallery: "Galerie",
  lawsAndRegulations: "Lois et Règlements",
  engZone: "Zone Des Ingénieurs",
  contactUs: "Contactez Nous",
  contactOea: "Contactez OEA",
  contactLaboratory: "Contactez Le Laboratoire",
  contactTrainingCenter: "Contactez Centre d'entrainements",
  trainingCenter: "Centre d'entrainements",
  "training-Center": "Centre d'entrainements",
  visitUs: "Visitez Nous",
  visitOea: "Visitez OEA",
  visitLaboratory: "Visitez Le Laboratoire",
  visitTrainingCenter: "Visitez Centre d'entrainements",
  latestFeeds: "Dernières Nouvelles",
  aboutUs: "A Propos De Nous",
  upcomingEvents: "Evénements Prochains",
  privacyPolicy: "Politique De Confidentialité",
  termOfUse: "Termes Et Conditions",
  services: "Services",
  shares: "partager",
  likes: "Aime",
  views: "Vues",
  All: "Tout",
  all: "Tout",
  "Training-Center": "Centre D'entrainement",
  "OEA-Support": "Support OEA",
  readMore: "Lis Plus",
  dateStart: "Date Début",
  startingFrom: "Début De:",
  dateEnd: "Date Fin ",
  instructors: "Instructeurs",
  alreadyRegistered: "Vous êtes déjà inscrit",
  registerClosed: "Les inscriptions sont clôturées",
  viewRegistrationDetails: "Voir les détails de l'enregistrement",
  register: "Registrez",
  allDay: "Tout Le Jours",
  start: "Début",
  ok: "D'accord",
  end: "Fin",
  Announcements: "Annonces",
  latestAnnouncements: "Dernières annonces",
  announcements: "Circulaires - Décisions du Conseil",
  Activities: "Activités",
  Mortality: "Mortalités",
  discountsAndBenefits: "Remises et Avantages",
  addCompany: "Ajouter L'entreprise",
  companyName: "Nom de l'entreprise",
  companyLocation: "Lieu de l'entreprise",
  uploadCompanyLocation: "Lieu de l'entreprise",
  uploadCompanyLogo: "Logo de l'entreprise",
  "Companies Directory": "Répertoire des entreprises",
  companyPhone: "Numero de l'entreprise",
  companyEmail: "Email de l'entreprise",
  companyWebsite: "Website de l'entreprise",
  companyDescription: "Description de l'entreprise",
  "Add Company": "Ajouter une entreprise",
  insurance: "Assurance",
  registrationTo: "Inscription pour",
  technicalDocuments: "Documents Techniques",
  MyRegistrationFor: "Mes Inscriptions Pour",
  myRegistration: "Mon Inscription",
  name: "Nom",
  email: "Courier électronique",
  phoneNumber: "Numéro de téléphone",
  whatsAppNumber: "WhatsApp",
  additionalInfo: "Information Additionelle",
  viewTrainingDetails: "Détails de l'entrainements",
  cancelRegistration: "Annuler l'enregistrement",
  editRegistration: "Changer l'enregistrement",
  engDirectory: "Annuaire des ingénieurs",
  skills: "Compétences",
  major: "Profession",
  myComplaints: "Mes suggestions",
  newComplaint: "Nouvelle suggestion",
  complaint: "Suggestion",
  complaintImage: "Image de la suggestion",
  complaintVoice: "Vocal de la suggestion",
  complaintDescription: "Description de la suggestion",
  complaintStatus: "Status de la suggestion",
  "MY SUGGESTIONS": "MES SUGGESTIONS",
  "ADD SUGGESTION": "AJOUTER UNE SUGGESTION",
  recordYourComplaint: "Enregistrez votre suggestion",
  uploadSuggestionImage: "Télécharger Votre Suggestion en Image",
  uploadingVoice: "Téléchargement de l'enregistrement vocal",
  complaintAdded: "Suggestion soumise",
  editComplaint: "Modifier la suggestion",
  edit: "Modifier",
  voiceFailedToLoad: "Échec du chargement, nouvelle tentative",
  complaintDeleted: "Suggestion supprimée",
  noComplaints: "Vous n'avez aucune suggestion",
  initiated: "Initié",
  share: "Partage",
  underInvestigation: "Sous enquête",
  closed: "Fermé",
  sureToDeleteComplaint: "Êtes-vous sûr de vouloir supprimer cette suggestion?",
  errorUploadingVoice: "Erreur lors du téléchargement de la voix",
  ciscoTrainings: "Entrainements Cisco",
  calculator: "Calculatrice",
  "Registration Fees": "Frais D'inscription",
  "Engineer Fees": "Frais D'ingenieur",
  back: "Retour",
  calculatorResultNote:
    "C'est un resultat provisoire. Vous devez contacter l'ordre pour avoir les tariffs précis",
  usdRate: "1 USD = 1500 LBP",
  engFees: "Frais D'ingenieur",
  penaltyRate: "Penalty rate",
  penaltyAmount: "Montant de la pénalité",
  numberOfLateYears: "Nombre d'années depuis l'obtention du diplôme",
  year: "Année(s)",
  registrationFees: "Frais d'affiliation",
  annualFees: "Frais annuels",
  priceOfTerritory: "Coût du projet",
  nbyears: "Nombre d'années",
  pickCalculationMethod: "Méthode de calcul",
  byArea: "Par surface",
  byCost: "Par coût",
  projectTotal: "Surface du projet",
  sqm: "M²",
  inputValue: "Entrer la valeur",
  projectCategory: "Catégorie du projet",
  projectSubCategory: "Sous-catégorie du projet",
  priceValueRange: "Gamme de prix",
  complexLevel: "Niveau complexe",
  calculate: "Calculer",
  constructionTable: "Table de construction",
  percentageOfEngineerFees: "Pourcentage des frais d'ingénieur",
  pricePer1sqm: "Prix par 1 m² (selon table)",
  percentageOfEngFees:
    "Pourcentage des honoraires d'ingénieur (selon le tableau)",
  primaryStudyFees: "Frais d'étude primaire APS",
  detailedStudyFees: "Frais d'étude détaillés APS",
  licenceFileFees: "Frais de dossier de licence",
  orderFees: "Frais de l'Ordre",
  orderFeesOutside: "Frais de l'Ordre - en dehors de Tripoli",
  designFees: "Frais de conception et de détails de direction",
  executiveDocumentFees: "Frais de document exécutif",
  supervisingFees: "Frais de supervision",
  constructionLicenseFees: "Valeur de la licence de travail",
  executivePlansFees: "Frais des plans exécutifs",
  result: "Résultat",
  select: "Sélectionner",
  simple: "Simple",
  normal: "Ordinaire",
  luxurious: "Luxueux",
  complicated: "Complexe",
  returnsToPartEng: "Retourné au co-ingénieur 40% des retours de OEA",
  electEngFees:
    "Payé à l'ingénieur électricien à partir des déclarations de OEA",
  mechEngFees:
    "Payé à l'ingénieur en mécanique à partir des déclarations de l'OEA",
  quotaPercentagePartFees:
    "Le pourcentage du total des frais du participant (selon le ratio de répartition des quotas)",
  elecPerc:
    "Pourcentage de la rémunération totale de l'ingénieur en électricité",
  mechanicalPerc:
    "Pourcentage de la rémunération totale de l'ingénieur en mécanique",
  remainingForMainEng:
    "Net restant pour l'ingénieur responsable des déclarations OEA",
  fundReceipts: "Valeur totale des recettes payables au Fonds",
  pensionFund: "Fonds De Pension",
  reports: "Rapports",
  payOnline: "Payer Electroniquement",
  polls: "Les sondages",
  jobs: "Emplois",
  position: "Position",
  deadline: "Date Limite",
  qualifications: "Conditions Necessaires",
  summary: "Résumé",
  jobDetails: "Détails",
  contactInfo: "Informations de contact",
  profile: "Mon Profil",
  facebook: "Facebook",
  instagram: "Instagram",
  linkedIn: "Linkedin",
  twitter: "Twitter",
  gender: "Genre",
  male: "Mâle",
  skills: "Compétences",
  searchSkill: "Rechercher dans les compétences",
  complaints: "Suggestions",
  engProfile: "Profil",
  cvUrl: "CV",
  female: "Femelle",
  uploadingPdf: "Téléchargement de PDF",
  errorUploadingPdf: "Erreur lors du téléchargement du fichier PDF",
  isPublicProfile: "Visible dans l'annuaire des ingénieurs",
  major: "Profession",
  myRegistrations: "Mes enregistrements",
  myComplaints: "Mes suggestions",
  save: "Sauvegarder",
  delete: "Effacer",
  submit: "Soumettre",
  next: "Suivant",
  register: "Registrez",
  firstName: "Prénom",
  middleName: "Prénom Du Père",
  lastName: "Nom",
  countryCode: "Code postal",
  facebookAccount: "Compte Facebook",
  twitterAccount: "Compte Twitter",
  emailAddress: "Addresse du Courier électronique",
  linkedinAccount: "Compte Linkedin",
  instagramAccount: "Compte Instagram",
  signin: "Connectez-vous",
  resetPassword: "Réinitialiser le mot de passe",
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  rememberMe: "Se souvenir de moi",
  joinOEA: "Nouveau compte",
  forgotPassword: "Mot de passe oublié",
  enroll: "Enroll",
  engineerId: "ID Ingénieur",
  firstName: "Prénom",
  changePhoneNumber: "Changer votre numéro de téléphone",
  unableToConnectToServer:
    "Une erreur est survenue, veuillez réessayer ultérieurement",
  email: "Courier électronique",
  phoneNumber: "Numéro de téléphone",
  enterEngId: "Veuillez entrer votre identifiant d'ingénieur",
  resetCodeReceived:
    "Vous avez reçu un code de réinitialisation du mot de passe?",
  rememberedPassword: "retour à",
  pleaseChooseResetPasswordMethod: "Recevoir le code via: ",
  sms: "Message Telephonique",
  requestToJoin: "Demande d'adhésion",
  tryAgainError: "Une erreur est survenue, veuillez réessayer ultérieurement",
  useCodeToLogin:
    "Veuillez utiliser la vérification du code pour vous connecter",
  enterNewPass: "Entrez un nouveau mot de passe",
  confirmNewPass: "Confirmer le nouveau mot de passe",
  alreadySentRequest: "Déjà envoyé une demande d'inscription?",
  verifyEnrollement: "Vérifier l'inscription",
  alreadyHaveAccount: "Vous avez déjà un compte?",
  confirmEnroll: "Confirmer l'inscription",
  signinSentence:
    "Connectez-vous pour bénéficier des services d'ingénieurs en ligne",
  smsCode: "Code de vérification SMS",
  retypePass: "Retaper le mot de passe",
  facingProblem: "Face à un problème ?",
  sendNewRequest: "Envoyer une nouvelle demande d'inscription",
  send: "Envoyer",
  newUser: "Nouvel utilisateur?",
  joinOea: "Rejoignez l'OEA",
  forgotPass: "Mot de passe oublié?",
  fullName: "Nom complet",
  addPerson: "Ajouter une personne",
  removePerson: "Supprimer la personne",
  comingSoon: "À venir",
  pleaseWait: "S'il vous plaît, attendez...",
  "register-to-event": "S'inscrire à l'événement",
  "register-to-training": "S'inscrire à la formation",
  home: "Acceuil",
  eorder: "Ordre Electronique",
  introSentence:
    "Pour effectuer vos opérations administratives et financières, veuillez cliquer sur le lien suivant:",
  clickHere: "Cliquez ici",
  renewJobDeclaration: "Renouveler la déclaration d'emploi",
  continueFeesPayment: "Continuer le paiement des frais",
  statementYear: "Attestation Professionnelle Pour L'année",
  generalInfo: "Informations Generales",
  engineerName: "Nom De L'ingenieur",
  chapter: "",
  subChapter: "",
  workType: "Type de travail",
  workLocation: "Lieu de travail",
  homeAddress: "Adresse du domicile",
  country: "Pays",
  province: "Province",
  city: "Ville",
  district: "Quartier",
  street: "rue",
  building: "Immeuble",
  floor: "Étage",
  appartment: "Appartement",
  mobile: "Mobile",
  isForMailing: "(Est pour l'envoi)",
  workAddress: "Adresse de travail",
  oops: "Oups! Un problème est survenu lors du chargement de la page",
  tryAgain: "Cliquez ici pour essayer à nouveau",
  law: `En application de l'article 8-9 du règlement intérieur :
        L'ingénieur doit noter personnellement tous les renseignements contenus dans la déclaration professionnelle lui appartenant sous sa responsabilité.
        Et que toute fausse information contenue dans la déclaration susvisée expose son propriétaire à la suspension des transactions dans le Syndicat
        conformément à une décision rendue par le Conseil du Syndicat à cet égard.
        Elle le soumet également aux sanctions disciplinaires prévues par la loi.`,
  IAccept: "J'accepte",
  clickToContinue: "Clique ici pour continuer",
  quotaLaw:
    "Seuls les ingénieurs-conseils (qui obtiennent un quota) sont autorisés à renouveler l'attestation professionnelle en début d'année, soit le firstDate. Quant aux autres ingénieurs, leurs données professionnelles sont renouvelées en début d'année syndicale, soit le secondDate.",
  visitOEA: `Vous ne pouvez pas changer votre position en ligne. Pour tout changement de statut d'ingénieur-conseil, vous devez vous présenter personnellement au syndicat <br/><br/>Ou contacter le numéro suivant : 06413110 <br/>Ou via e-mail : info@oea-Tripoli.org.lb`,
  statusPreviousYear:
    "Le statut de votre attestation professionnelle pour l'année précédente est :",
  changePosition: "Changer de position",
  continueSamePosition: "Continuité dans la même position",
  delayVisitOea: `La date de votre dernière déclaration professionnelle remonte à plus d'un an, vous devez donc vous présenter personnellement au syndicat <br/><br/>Ou contacter le numéro suivant : 06413110 <br/>Ou par e-mail : info@oea-Tripoli.org.lb`,
  everythingUpToDate:
    "Vous avez déjà renouvelé votre déclaration d'emploi. Vous pouvez seulement voir votre ancienne déclaration d'emploi ",
  updatesText: `En cas de changement, veuillez modifier votre adresse personnelle et votre adresse professionnelle`,
  uploadDocuments: "Veuillez télécharger ces documents",
  noDocuments: "Aucun document requis",
  remarks: "Remarques",
  responsibility:
    "Les informations reçues sont sous votre responsabilité, et toute fausse information peut vous exposer à la suspension de vos transactions et à des sanctions disciplinaires.",
  finish: "Terminer la transaction",
  enterPassword: "Entrer Le Nouveau Mot De Passe",
  workForEngineer: "Travail Pour Un Ingénieur",
  workForEngineerID: "Travail Pour Un Ingénieur ID",
  partnerEngineer: "Ingénieur Partenaire",
  partnerEngineerId: "Ingénieur Partenaire ID",
  quotaForEngineer: "Ingénieur Beneficiaire Du Quota",
  quotaForEngineerID: "Ingénieur Beneficiaire Du Quota ID",
  govInstitute: "Gov Institute",
  govInstituteID: "Gov Institute ID",
  govInstituteDesc: "Description Gov Institute",
  downloadFile: "Pour telecharger le contrat de partenariat, cliquez ici",
  downloadFileReceipt: "Pour telecharger le reçu, cliquez ici",
  pictureNote:
    "Veuillez noter que vous ne pouvez télécharger qu'une image ou un fichier pdf d'une taille maximale de 1 Mo",
  bigFile: "Veuillez télécharger un fichier de moins de 1 annualSubscriptionMo",
  requestEnrollment:
    "Votre demande est en cours d'examen<br/><br/> Nous vérifierons vos informations et vous enverrons un code sur votre numéro et email pour pouvoir vous connecter",
  userDoesNotExists: "Numéro d'utilisateur n'existe pas",
  wrongPassword: "Mot de passe incorrecte",
  wrongData: "Données Incorrectes",
  alreadyExists: "Utilisateur existe déjà",
  doseNotExists: "Utilisateur n'existe pas",
  emailDoesNotExists: "Email invalide",
  pendingReq: "Votre demande est en attente d'approbation",
  expiredCode: "Le code de vérification a expiré",
  requestDoesNotExist: "La demande n'existe pas",
  codeUsed: "Le code de vérification déjà utilisé",
  wrongCode: "Code de vérification incorrect",
  confirmEnrollment: "Confirmer l'inscription ",
  yourCV: "Votre CV",
  updateTypeLocationWork: "Modifier le type et le lieu de travail",
  requestExists: "Vous avez déjà envoyé une demande",
  engZoneSentence:
    "La Zone des Ingénieurs offre à ses membres un bouquet personnalisé de services et de fonctionnalités pour améliorer leur expérience et répondre à leurs besoins.",
  loginSentence: `Connectez-vous et profitez de la zone des ingénieurs OEA`,
  "Building License Transactions": "Transactions de licence de construction",
  "Restoration / Connection Permit / Demolition / Designation":
    "Rétablissement / Connexion Permis / Démolition / Désignation",
  searchIn: "Rechercher dans",
  registrationclosed: "inscription fermée",
  noDataAvailable: "Pas De Données Disponibles",
  noTrainingsAvailable: "Pas De Formations Disponibles",
  wrongConfirmPassword: "Merci de vérifier que vos mots de passe correspondent",
  wrongOldPassword: "Votre ancien mot de passe semble être incorrect",
  changePassword: "Modifier le mot de passe",
  passwordChangedSuccessfully:
    "Votre mot de passe a été changé avec succès. Utilisez votre nouveau mot de passe pour vous connecter",
  personInfo: "Informations Personelles",
  status: "Statut",
  financialStatement: "États Financiers",
  mandatoryUnpaidDues: "Cotisations impayées obligatoires",
  optionalUnpaidDues: "Cotisations impayées",
  unpaidReceipt: "Reçu impayé",
  date: "Date",
  dueDate: "Date d'échéance",
  amount: "Montant",
  numberReceipt: "Numéro de reçu",
  description: "Description",
  payReceiptB:
    "Veuillez payer vos reçus impayés avant d'émettre un nouveau reçu",
  createdJobDeclaration: "Vos transactions administratives sont terminées",
  generatedReceipt: "Votre transaction financière a été complétée avec succès",
  printReceipt: "Retour à l'impression du reçu",
  noFinancialStatment: "Vous n'avez aucune transaction financière",
  print: "Imprimer",
  cantPrint:
    "Cette transaction est ancienne, si elle a été payée, merci d'en envoyer une copie à l'email suivant",
  skip: "Sauter",
  batch: "lot",
  paymentTerms: "Modalités de paiement",
  years: "Les années",
  withoutInterest: "sans intérêt",
  and: "et",
  feeAmount: "Montant des frais",
  interestAmount: "Montant des intérêts",
  pendingOperation:
    "Vous avez une précédente transaction d'abonnement annuel, SVP contacte le dpt financier",
  annualSubscription: "Souscription annuelle",
  fileCode: "Code de fichier",
  engineerNumber: "Numéro d'ingénieur",
  fileType: "Type de fichier",
  description: "Descriptif",
  notes: "Remarques",
  dateOfReceipt: "Date de réception",
  deliveryDate: "Date de livraison",
  currentStatusFile: "L'état actuel du dossier",
  lastUserHasFile: "Le nom du dernier utilisateur ou employé qui a le dossier",
  licenseFileSentence:
    "Pour effectuer vos transactions techniques , veuillez cliquer sur le lien suivant:",
  licenseFile: "Transactions techniques",
  enterFileCode: "Entrez votre code d'opération",
  extension: "Extension",
  employeeName: "Nom de l'Employé",

  //SEO Description
  aboutOeaDescription:
    "A propos de l'ordre des ingénieurs et architectes à Tripoli",
  boardmembersDescription:
    "Conseil des membres de l'ordre des ingénieurs et architectes de Tripoli",
  branchesDescription:
    "Branchyes de l'ordre des ingénieurs et architectes de Tripoli",
  committeesDescription:
    "Les commissions de l'ordre des ingénieurs et architectes de Tripoli",
  organizationDescription:
    "L'organigramme de l'ordre des ingénieurs et architectes de Tripoli",
  benefitsDescription:
    "Réductions et avantages offerts par l'Ordre des Ingénieurs et Architectes de Tripoli",
  companiesDescription:
    "Annuaire des entreprises où vous pouvez trouver de nombreuses entreprises avec leurs coordonnées",
  contactusDescription:
    "Contactez l'ordre des ingénieurs et des architectes de Tripoli",
  insuranceDescription:
    "Service d'assurance offert par l'ordre des ingénieurs et architectes à Tripoli",
  engineersDirectoryDescription:
    "Annuaire des ingénieurs où vous pouvez trouver un grand nombre de profils d'ingénieurs avec leurs coordonnées",
  jobDeclarationDescription:
    "Renouveler la déclaration d'emploi des ingénieurs en ligne et de manière rapide",
  eventsDescription:
    "Liste de tous les événements réalisés par l'ordre des ingénieurs et architectes à Tripoli",
  galleryDescription:
    "Galerie de photos des événements et des activités en collaboration avec l'ordre des ingénieurs et des architectes à Tripoli",
  lawsDescription:
    "Lois et règlements de l'ordre des ingénieurs et des architectes à Tripoli",
  complaintsDescription: "Suggestions postées par les ingénieurs",
  myregistrationsDescription:
    "Mes inscriptions aux manifestations et formations de l'ordre des ingénieurs et architectes à Tripoli",
  newsDescription:
    "Liste de toutes les actualités et annonces de l'ordre des ingénieurs et architectes à Tripoli",
  technicalDocumentsDescription:
    "Documents techniques pouvant être utiles aux ingénieurs",
  trainingsDescription:
    "Liste de toutes les formations proposées par l'ordre des ingénieurs et architectes à Tripoli",
  visitusDescription:
    "Visitez l'ordre des ingénieurs et des architectes à Tripoli",
  engineerZoneDescription:
    "La zone ingénieurs offre une variété de services et de bouquets pour tous les ingénieurs et architectes",
  homepageDescription:
    "Page d'accueil du site officiel de l'ordre des ingénieurs et architectes de Tripoli",
  jobsDescription:
    "Emplois affichés sur le site officiel de l'ordre des ingénieurs et architectes à Tripoli",
  reportsDescription:
    "Rapports et statistiques relatifs à l'ordre des ingénieurs et architectes à Tripoli",
  calculatorDescription:
    "Le calculateur contenant les frais d'inscription et les frais d'ingénieur aide les ingénieurs à calculer les frais facilement et rapidement",
  technicalOfficePubs: "Publications du bureau technique",
  technicalOffice: "Bureau technique",
  download: "Telecharger",
  arbitration: "Centre d'arbitrage",
  arbitrationCenter: "Centre d'Arbitrage Technique",
  deleteAccount: "Supprimer votre compte",
  deleteAccountMsg: "Pour supprimer votre compte, veuillez nous contacter",
  statuteOfArbitration: "Statut du Centre d'Arbitrage d'Ingénierie",
  arbitrationRules:
    "Règlement d'arbitrage sur le Centre d'Arbitrage Ingénierie",
  arbitrationCosts: "Frais d'arbitrage",
  arbitrationClause: "Clause d'arbitrage",
  arbitrationRequest: "Demande d'arbitrage",
  respondingToArbitrationRequest: "Réponse à la demande d'arbitrage",
  arbitrationAcceptance:
    "Déclaration d'acceptation et d'indépendance de l'arbitre",
  formsAndDocs: "Formulaires et documents",
  presidentWord: "Mot du président",
  syndicateOverview: "À propos du syndicat",
  joinOea: "Adhérer au syndicat, conditions d'adhésion",
  financialAndMedicalTransactions: "Opérations Financières & Santé",
  financialAndMedicalTransactionsDesc: `Workers' Teams croit en la couverture des hospitalisations et des interventions chirurgicales pour les ingénieurs affiliés à des syndicats, leurs familles, leurs parents, leurs frères et sœurs et leurs familles.
Le contrat d'assurance pour l'année syndicale débute le premier mars de chaque année et se termine fin février de l'année suivante, et la dernière date limite pour souscrire à l'assurance est la fin avril.`,
  monthlyReports: "Rapports mensuels",
  webinarsSchedule:
    "Calendrier des conférences et séminaires mensuels dans le bâtiment syndical",
  sideMenuTitle: "Menu",
  changeMode: "Changer de mode",
  signIn: "Connectez-vous",
  signUp: "S'inscrire",
  moreAnnouncements: "Plus d'annonces",
  moreNews: "Plus de Nouvelles",
  noInfo: "Aucune information",
  nakabaBuilding: "Bâtiment de guilde",
  internalNumbers: "Symbole du bureau interne",
  searchPlaceholder: "Recherchez votre pays",
  noCountry: "Aucun pays avec ce nom",
  akkarBuilding: "Bureau d'Akkar",
  lab: "Le laboratoire",
  currentTrainings: "Formations actuelles",
  futureTrainings: "Formations à venir",
  pastTrainings: "Formations passées",
  membershipTerms: "conditions d'adhésion",
  technicalOfficeDesc: `Bienvenue sur la page de la section d'enregistrement des transactions.
Le bureau technique enregistre les transactions de construction, d'arpentage et de sol appartenant aux ingénieurs affiliés au Syndicat des Ingénieurs de Tripoli.`,
  previousCouncils: "Conseils précédents",
};
