import { isArray } from './utils'

export function getTranslationContent(data, name, lang) {
    let result = data && data?.[name];

    let translation =
        isArray(data?.liveTranslations) &&
        data?.liveTranslations.find(t =>
            t?.lang == lang && t?.fieldName == name
        );
    if (translation?.content && translation?.content != '') {
        result = translation.content;
    }

    return result;
}