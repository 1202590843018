import Image from "next/image";
import Text from "./Text";
import React from "react";
import { twMerge } from "tailwind-merge";
import Link from "next/link";
import isEmpty from "lodash/isEmpty";
import { Icon } from "@iconify/react";

const Button = ({
  title,
  className = "",
  onClick,
  disabled = false,
  type = "button",
  IconComp,
  img,
  whiteBg,
  linkProps,
  iconClassName,
  iconName,
  outline,
  mainClass = "",
  center
}) => {
  const getRightComponent = () => {
    let rightIcon = null;
    //icon using iconify library

    if (iconName)
      rightIcon = (
        <Icon
          icon={iconName}
          className={`mx-2 ${outline ? "text-orange " : "text-white"} ${
            iconClassName ? iconClassName : ""
          }`}
          fontSize={12}
        />
      );
    else if (IconComp)
      rightIcon = (
        <IconComp
          className={twMerge(whiteBg ? "text-orange" : "text-white", "ml-1 mr-0 rtl:mr-1 rtl:ml-0")}
        />
      );
    //icon or image from public folder
    else if (img)
      rightIcon = <Image alt="icon" width={20} height={20} src={img} />;

    return rightIcon;
  };
  const getButtonWrapper = (children) => {
    return linkProps && !isEmpty(linkProps) ? (
      <Link href={linkProps?.href} as={linkProps?.as || ""}>
        {children}
      </Link>
    ) : (
      <div>{children}</div>
    );
  };
  return getButtonWrapper(
    <div
      className={twMerge(
        "mt-6",
        mainClass,
        center ? "flex justify-center" : ""
      )}
    >
      <button
        className={twMerge(
          "flex rounded-btn py-2 px-4 justify-center items-center",
          disabled ? "bg-disabled" : whiteBg ? "bg-white" : "bg-orange",
          outline ? "border-2 border-orange" : "",
          className
        )}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        <Text orange={whiteBg} white={!whiteBg} text={title} />
        {getRightComponent()}
      </button>
    </div>
  );
};

export default Button;
