"use client";

const Text = ({
  sectionTitle,
  className = "",
  text,
  center,
  inputFontS,
  red,
  infoSize,
  disableSelect,
  left,
  orange,
  white,
  direction,
  subtitle,
  bold,
  mainColor,
  content,
  disabledColor
}) => {
  let classNew = "text-black ";

  if (sectionTitle) classNew = classNew + "text-sectionTitle ";
  if (center) classNew = classNew + "text-center ";
  if (left) classNew = classNew + "text-left ";
  if (inputFontS) classNew = classNew + "text-input ";
  if (red) classNew = classNew + "text-red ";
  if (infoSize) classNew = classNew + "text-infoText ";
  if (disableSelect) classNew = classNew + "select-none ";
  if (orange) classNew = classNew + "text-orange  ";
  if (white) classNew = classNew + "text-white  ";
  if (subtitle) classNew = classNew + "text-subtitle  ";
  if (bold) classNew = classNew + "font-[JannaLTBold] ";
  if (mainColor) classNew = classNew + "text-oeaMain ";
  if (content) classNew = classNew + "text-content ";
  if (disabledColor) classNew = classNew + "text-disabled ";

  return (
    <p dir={direction} className={`${classNew} ${className}`}>
      {text}
    </p>
  );
};

export default Text;
